/* eslint-disable @typescript-eslint/no-explicit-any */
import { StudioNodeData } from '@common/studio-types';
import { useAbly } from 'ably/react';
import { useEffect } from 'react';

type Args = {
  episodeId: string;
  onNodeChange: (node: StudioNodeData[]) => void;
};

export const useRealtimeStudioFlow = ({ episodeId, onNodeChange }: Args) => {
  const realtime = useAbly();

  useEffect(() => {
    const channel = realtime.channels.get(`studioFlow:${episodeId}`);

    channel.subscribe((evt: any) => onNodeChange([evt.data as StudioNodeData]));

    setTimeout(() => {
      channel.history().then((history: any) => {
        const allData: StudioNodeData[] = history.items.map(
          ({ data }: any) => data,
        );
        onNodeChange(allData);
      });
      // we should wait 1 second before we get the history to make sure we'll be able to pull
      // all messages that were not processed and reduce risk of race-conditions
    }, 2000);

    return () => channel.unsubscribe();
  }, [realtime]);
};
