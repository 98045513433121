import { BadgeProps } from '@chakra-ui/react';
import { splitVariables } from '@common/studio-types';
import styled from 'styled-components';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { ValueBadge } from './ValueBadge';

type Props = { text: string; badgeProps?: BadgeProps };

export const InterpolatedText: React.FC<Props> = ({ text, badgeProps }) => {
  const { findPropertyByName } = useStudioRpgConfig();

  return (
    <Text>
      {splitVariables(text, findPropertyByName).map((item, index) => (
        <span key={index}>
          {typeof item === 'string' ? (
            item
          ) : (
            <ValueBadge value={item} badgeProps={badgeProps} />
          )}
        </span>
      ))}
    </Text>
  );
};

const Text = styled.div`
  white-space: pre-line;
`;
